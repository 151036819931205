<template>
  <div class="list">
    <div class="top-item">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="关键字">
          <el-input placeholder="请输入机构名称/管理员电话/管理员姓名" v-model="form.searchValue"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
        <el-form-item class="add-tips" label="新增机构提醒">
          <el-switch active-color="#13ce66"
                     inactive-color="#ff4949"
                     :active-value="1"
                     :inactive-value="0"
                     v-model="form.tips"></el-switch>
        </el-form-item>
        <el-form-item label="">
          <el-input v-show="form.tips === 1" v-model="form.noticePhone" placeholder="请填写短信发送手机号"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="add-btn">
      <el-button type="primary"  @click="addOrg">点击新增</el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        v-loading="loading"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          label="机构名称"
          align="center"
          width="240">
        </el-table-column>
        <el-table-column
          prop="phone"
          align="center"
          label="管理员电话">
        </el-table-column>
        <el-table-column
          prop="adminName"
          align="center"
          label="管理员姓名">
          <template slot-scope="{ row }">{{row.adminName || '-'}}</template>
        </el-table-column>
        <el-table-column
          prop="status"
          align="center"
          label="状态"
          width="180">
          <template slot-scope="{ row }">
            <el-switch
              class="table-scope"
              v-model="row.status"
              :active-value="1"
              active-color="#13ce66"
              :inactive-value="2"
              inactive-color="#ff4949"
              @change="changeOrgStatus(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="limitTime"
          label="使用到期时间"
          align="center">
          <template slot-scope="{ row }">
            <span>{{row.type === 1 ? row.limitTime : '无期限'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="操作"
          width="180"
          fixed="right">
          <template slot-scope="{ row }">
            <div class="com-operation">
              <span class="edit-btn" @click="$refs.orgDialogRef.open(row)">修改</span>
              <el-divider direction="vertical"></el-divider>
              <span class="edit-btn" @click="$refs.dateSetDialogRef.open(row)">设置期限</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination" v-if="tableData.length">
      <el-pagination
        :total="total"
        :current-page="form.current"
        :page-size="form.size"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, prev, pager, next, sizes"
      ></el-pagination>
    </div>
    <!--  机构弹窗  -->
    <orgDialog ref="orgDialogRef" @onSuccess="onSuccess"></orgDialog>
    <dateSetDialog ref="dateSetDialogRef" @onSuccess="onSuccess"></dateSetDialog>
  </div>
</template>

<script>
import orgDialog from "views/admin/institution/orgDialog";
import dateSetDialog from "views/admin/institution/dateSetDialog";
import { getOrgList, frozenOrg } from '@/api/admin/institution'
export default {
  components:{
    orgDialog,dateSetDialog
  },
  data() {
    return {
      loading: true,
      form: {
        searchValue: '',
        tips: 1,
        noticePhone: '',
        current: 1,
        size: 10,
      },
      tableData: [],
      total: 0,
    }
  },
  created() {
    this.getOrgLists()
  },
  methods: {
    // 搜索
    onSubmit() {
      this.form.current = 1
      this.getOrgLists()
    },
    // 重置
    handleReset(){
      this.form.current = 1
      this.form.size = 10
      this.form.searchValue = ''
      this.form.noticePhone = ''
      this.getOrgLists()
    },
    // 新增机构
    addOrg(){
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      let phoneNumber = ''
      if (this.form.tips === 1 && this.form.noticePhone.trim()) {
        phoneNumber= this.form.noticePhone
        if(!reg.test(this.form.noticePhone)){
          this.$message.error('请输入正确的手机号')
          return
        }
      }
      this.$refs.orgDialogRef.open(null,phoneNumber)
    },
    // 获取机构列表
    getOrgLists(){
      this.loading = true
      getOrgList(this.form).then(res => {
        this.tableData = res.data.records
        this.total = Number(res.data.total)
      }).finally(()=>{
        this.loading = false
      })
    },
    // 冻结/解冻机构
    changeOrgStatus(row){
      frozenOrg({id: row.id}).then(() => {
        this.$message.success(row.status == 1 ? '解冻成功' : '冻结成功')
      }).finally(() => {
        this.getOrgLists()
      })
    },
    handleSizeChange(size) {
      this.form.size = size;
      this.form.current = 1;
      this.getOrgLists()
    },
    handleCurrentChange(page) {
      this.form.current = page;
      this.getOrgLists()
    },
    // 弹窗回调
    onSuccess(id){
      // 有id编辑成功，原地刷新；新增成功，重置首页
      id ? this.getOrgLists() : this.handleReset();
    },
  }
}
</script>

<style lang="less" scoped>
.add-tips{
  margin: 0 30px 0 50px;
}
.el-form-item{
  margin-bottom: 16px;
}
.el-input{
  width: 262px;
}
.demo-form-inline{
  border-bottom: 1px solid #dcdfe7;
}
.add-btn{
  margin: 15px 0 20px;
}
.com-operation {
  cursor: pointer;
  .edit-btn {
    color: #1FB3F7;
  }
}
.pagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
